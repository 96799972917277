import React from 'react';

export default {
    headline: (
        <i>
            Deine <strong>Punktehistorie</strong>
        </i>
    ),
    paragraph1:
        'Du möchtest wissen, wann Du wofür Punkte erhalten oder sie ausgegeben hast? In Deiner Punktehistorie findest Du alle Informationen.',
    overview: <strong>Übersicht Deiner Punkte</strong>,
    paragraph2:
        'Hier kannst Du Dir Deine gesammelten und ausgegebenen Punkte im Überblick anschauen.',
    tableHeadings: ['Datum', '', 'Punkte'],
};
