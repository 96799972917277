import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
    loadPointsHistory,
    selectCurrentPage,
    selectTotalPages,
    selectHistory,
} from '../../../../model/points-history';

import {Section, Container, Row, Column} from '../../../layout/Grid';
import Paragraph from '../../../components/Paragraph';
import {ReactComponent as PunktehistorieSvg} from '../../../../assets/images/punktehistorie/img-punktehistorie.svg';
import Headline from '../../../components/Headline';
import Pagination from '../../../components/Pagination';
import lang from '../../../../lang/pages/punktehistorie.lang';
import Table from '../../../components/Table';
import pointsHistoryDataRenderer from './pointsHistoryDataRenderer';
import {sectionThemeNames} from '../../../layout/Grid/Section.styles';

export const PointsHistory = ({
    theme,
    history,
    totalPages,
    currentPage,
    onPageChange,
}) => {
    const handleButtonClick = (page) => {
        if (currentPage !== page) {
            onPageChange(page);
        }
    };
    return (
        <Section theme={theme}>
            <Container width="narrow">
                <Row>
                    <Column xs={12} className="text-center">
                        <PunktehistorieSvg />
                        <Headline type="h6">{lang.overview}</Headline>
                        <Paragraph>{lang.paragraph2}</Paragraph>
                    </Column>
                    <Column xs={12}>
                        <Table
                            theme={theme}
                            cols={lang.tableHeadings}
                            rows={history}
                            dataRenderer={pointsHistoryDataRenderer}
                        />
                    </Column>
                    <Column xs={12}>
                        <Pagination
                            totalPages={totalPages}
                            currentPage={currentPage}
                            handleButtonClick={handleButtonClick}
                        />
                    </Column>
                </Row>
            </Container>
        </Section>
    );
};

PointsHistory.propTypes = {
    theme: PropTypes.oneOf(sectionThemeNames),
    history: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            date: PropTypes.string,
            message: PropTypes.string,
            change: PropTypes.string,
        })
    ),
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
    onPageChange: PropTypes.func,
};

PointsHistory.defaultProps = {
    theme: 'default',
    history: [],
};

const ConnectedPointsHistory = (props) => {
    const pointsHistory = useSelector(selectHistory);
    const currentPage = useSelector(selectCurrentPage);
    const totalPages = useSelector(selectTotalPages);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadPointsHistory());
    }, [dispatch]);

    const handlePageChange = (page) => {
        dispatch(loadPointsHistory(page));
    };

    return (
        <PointsHistory
            {...props}
            history={pointsHistory}
            onPageChange={handlePageChange}
            currentPage={currentPage}
            totalPages={totalPages}
        />
    );
};

export default ConnectedPointsHistory;
