import React from 'react';
import PropTypes from 'prop-types';

import Headline from '../../../../components/Headline';
import {Item} from '../../../../components/Table/styles';

// Spaltennamen: ['Datum', '', 'Punkte'] (siehe tableHeadings in punktehistorie.lang.js)

const pointsHistoryDataRenderer = (
    type,
    colIndex,
    headings,
    rowIndex,
    data
) => {
    if (type === 'heading') {
        return (
            <Headline
                key={`${rowIndex}.${colIndex}`}
                type="h6"
                fontWeight="bold"
                flexGrow={colIndex === 1 ? 4 : 1}
                hideTopMargin
                hideBottomMargin>
                {headings[colIndex]}
            </Headline>
        );
    } else if (type === 'data') {
        switch (colIndex) {
            case 0:
                return (
                    <Item key={`${rowIndex}.${colIndex}`} bold>
                        {data[rowIndex].date}
                    </Item>
                );
            case 1:
                return (
                    <Item key={`${rowIndex}.${colIndex}`} flexGrow={4}>
                        {data[rowIndex].message}
                    </Item>
                );
            case 2:
                return (
                    <Item key={`${rowIndex}.${colIndex}`} bold red>
                        {data[rowIndex].change}
                    </Item>
                );
            default:
                return null;
        }
    }
};

pointsHistoryDataRenderer.propTypes = {
    type: PropTypes.string,
    colIndex: PropTypes.number,
    headings: PropTypes.array,
    rowIndex: PropTypes.number,
    data: PropTypes.array,
};

export default pointsHistoryDataRenderer;
