import {createSelector} from 'reselect';
export const selectDomain = (state) => state.pointsHistory;

export const selectHistory = createSelector(
    selectDomain,
    (domain) => domain.history
);

export const selectPaginationData = createSelector(
    selectDomain,
    (domain) => domain.pagination
);

export const selectCurrentPage = createSelector(
    selectPaginationData,
    (pagination) => pagination.currentPage
);

export const selectTotalPages = createSelector(
    selectPaginationData,
    (pagination) => pagination.totalPages
);
