import React from 'react';

import lang from '../lang/pages/punktehistorie.lang';
import Layout from '../ui/layout/Layout';
import Link from '../ui/components/Link';
import {Section, Container} from '../ui/layout/Grid';
import Paragraph from '../ui/components/Paragraph';
import Headline from '../ui/components/Headline';
import PointsHistory from '../ui/domains/sales-agent-user-interactions/UserPointsHistory';
import {routes} from '../model/navigation/routes';

export const Punktehistorie = () => {
    return (
        <Layout>
            <Section>
                <Container width="medium">
                    <Link href={routes.team} icon="arrowBack" iconLeft>
                        zurück
                    </Link>
                </Container>
                <Container width="narrow" className="text-center">
                    <Headline type="h1">{lang.headline}</Headline>
                    <Paragraph>{lang.paragraph1}</Paragraph>
                </Container>
            </Section>
            <PointsHistory />
        </Layout>
    );
};

export default Punktehistorie;
